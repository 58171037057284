<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl" @goToCampaignDashboard="goToCampaignDashboard" @openLibraryDialog="openLibraryDialog"/>
            <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2';
import GenericDataService from '@/services/GenericDataService';
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";

export default {
    name: "CampaignEmailList",
    components: { DatatableV2, LibraryDialogComponent },
    data() {
        return {
            apiUrl: "/retroplanning/list?type=CAMP_OUT_P&sousType=campEMAILout",
            formLib: null,
            libType: "EMAIL",
            showLibraryDialog: false,
        }
    },
    methods: {
        goToCampaignDashboard(object, actions) {
            if(object.operation_id && object.operation_id.id) {
                this.$router.push({ path: actions.actions.SHOW.route.replace('{{id}}', object.id).replace('{{operation_id}}', object.operation_id.id) });
            } else {
                this.$router.push({ path: actions.actions.SHOW_WITHOUT_OPE.route.replace('{{id}}', object.id) });
            }
        },
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
    }
}
</script>